'use strict'

var ModuleGeneral = function ModuleGeneral(){}

import validate from 'jquery-validation';

$(function() {
  
  /*
  ** Validate 購入フォーム
  */
  if($('#form').length) {
    $('#zipcode').on('blur', function() {
      $('input[name="address1"]').valid();
      $('input[name="address2"]').valid();
    });
    
    $.extend($.validator.messages, {
      required: '必須項目です。',
      equalTo: 'メールアドレスが一致しません。',
      isMail: '正しいメールアドレスを入力ください。',
      isZip: '正しい郵便番号でご入力ください。',
      isPhone: '正しい電話番号でご入力ください。',
    });

    var methods = {
      isMail: function(value, element) {
        var mail = value.split('@');
        if (mail[0].slice(0, 1) === '.' || mail[0].slice(-1) === '.') {
          return false
        };

        if (value.slice(-1) === '.') {
          return false
        };

        if (value.indexOf('..') !== -1) {
          return false
        };

        return this.optional(element) || /^[a-zA-Z0-9!#$%'*+/=?^_`{|}~.-]+@[0-9a-zA-Z-]+\.[0-9a-zA-Z.-]+$/.test(value);
      },
      isPhone: function(value, element){
        //固定電話、携帯電話兼用。
        value = value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
        return this.optional(element) || /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value);
      },
      isZip: function(value, element){
        // value = value.replace(/\D/g ,"");
        return this.optional( element ) || /^\d{7}$/.test(value);
      },
    };

    $.each(methods, function(key) {
      $.validator.addMethod(key, this);
    });

    $('#form').validate({
      rules: {
        order_cd: {
          required: true,
        },
        order_blu: {
          required: true,
        },
        name1: {
          required: true,
        },
        name2: {
          required: true,
        },
        zipcode: {
          required: true,
          isZip: true,
          maxlength: 7
        },
        prefecture: {
          required: true,
        },
        address1: {
          required: true,
        },
        address2: {
          required: true,
        },
        telno: {
          required: true,
          isPhone: true,
          maxlength: 11
        },
        email: {
          required: true,
          isMail: true,
        },
        email_confirmation: {
          required: true,
          isMail: true,
          equalTo: "#email"
        },
        privacypolicy: {
          required: true,
        }
      },
      messages: {
        zipcode: {
          maxlength: "7文字以内でご入力ください。",
        },
        telno: {
          maxlength: "11文字以内でご入力ください。",
        }
      },
      errorElement: 'span',
      errorClass: 'err',
      errorPlacement: function(error, element) {
        if(element.attr('id') == 'privacypolicy') {
          var target = $('#privacypolicy').closest('label');
          error.insertAfter(target).addClass('err_msg');
        } else if(element.attr('id') == 'order_cd' || element.attr('id') == 'order_blu') {
          var target = $('#' + element.attr('id')).closest('.select');
          error.insertAfter(target).addClass('err_msg');
        } else {
          error.insertAfter(element).addClass('err_msg');
        }
      }
    });
  };

});

export default ModuleGeneral;