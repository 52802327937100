'use strict'

import '../styles/variables.scss';
import '../styles/foundation.scss';
import '../styles/layout.scss';
import '../styles/form.scss';
import '../styles/component.scss';
import '../styles/project.scss';
import '../styles/utility.scss';

var General  = require('./modules/main');
